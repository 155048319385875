@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.html,
.bd {
  display: grid;
  width: 100%;
  height: 100%;
  place-items: center;
 
  background: -webkit-linear-gradient(left, #a445b2, #fa4299);
}
label{
    color: lightslategray;
}

.wrapper {
  max-width: 390px;
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.wrapper .title-text {
  display: flex;
  width: 200%;
}

.wrapper .title-text .title {
  width: 50%;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .form-container {
  width: 100%;
  overflow: hidden;
}

.form-container .slide-controls {
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
}

.slide-controls .slide {
  color: #fff;
  height: 100%;
  width: 100%;
  z-index: 1;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  transition: all 0.6s ease;
}

.slide-controls .signup {
  color: #000;
}

.slide-controls .slide-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  border-radius: 5px;
  z-index: 0;
  background: -webkit-linear-gradient(left, #a445b2, #fa4299);
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
input[type="checkb"] {
  display: none;
}

#signup:checked ~ .slide-tab {
  left: 50%;
}

#signup:checked ~ .signup {
  color: #fff;
}

#signup:checked ~ .login {
  color: #000;
}

.form-container .form-inner {
  display: flex;
  width: 200%;
}

.form-container .form-inner form {
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-inner form .field {
  height: 50px;
  width: 100%;
  margin-top: 20px;
}

.form-inner form .field input {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 17px;
  padding-left: 15px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  transition: all 0.4s ease;
}

.form-inner form .field input:focus {
  border-color: #fc83bb;
}

.form-inner form .pass-link {
  margin-top: 5px;
}

.form-inner form .pass-link a,
.form-inner form .signup-link a {
  color: #fa4299;
  text-decoration: none;
}

.form-inner form .signup-link {
  text-align: center;
  margin-top: 30px;
}

.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover {
  text-decoration: underline;
}

form .field input[type="submit"] {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  padding-left: 0px;
  border: none;
  cursor: pointer;
  background: -webkit-linear-gradient(left, #a445b2, #fa4299);
}
